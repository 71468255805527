.game {
  margin: 1em 1em;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.game div {
  margin: 0 auto;
}
.controls {
  min-width: 50%;
  max-width: 31rem;
}
.chosen {
  -webkit-box-shadow: 3px 3px 11px 3px rgba(13, 13, 13, 0.28);
  -moz-box-shadow: 3px 3px 11px 3px rgba(13, 13, 13, 0.28);
  box-shadow: 3px 3px 11px 3px rgba(13, 13, 13, 0.28);
}
button {
  padding: 0.5em;
  border-radius: 3px;
  font-size: medium;
  background-color: white;
  border: 1px solid lightgray;
  border-bottom: 1px solid rgb(180, 180, 180);
  cursor: pointer;
  color: rgb(60, 60, 60);
}
button:active {
  background-color: whitesmoke;
  outline: none;
  color: rgb(60, 60, 60);
}
button:focus {
  outline: none;
}
@media (hover: hover) {
  button:hover {
    background-color: whitesmoke;
  }
  button:active {
    background-color: lightgray;
    outline: none;
    color: rgb(60, 60, 60);
  }
}
.letter {
  border: 1px solid rgb(211, 211, 211);
  border-radius: 5px;
  padding: 6px 10px;
  margin: 0.2em;
  width: 40px;
  height: 40px;
  line-height: 2rem;
  font-size: xx-large;
  position: relative;
  cursor: pointer;
}

.letters {
  margin: 2em;
  display: flex;
  flex-direction: row;
  max-width: fit-content;
}
.value-in-hand {
  position: absolute;
  font-size: xx-small;
  z-index: 100;
  top: -1.5em;
  right: 0.5em;
}
footer {
  text-align: center;
  margin-bottom: 1rem;
  color: grey;
}
footer a {
  color: grey;
}
.table-score,
.table-result {
  margin: auto;
  border: 0.5px solid lightgrey;
  border-collapse: collapse;
}
.table-score tr td {
  border: 0.5px solid lightgrey;
  padding: 0.2em 1em;
}
.table-result tr td {
  border: 0.5px solid lightgrey;
  padding: 0.2em 1em;
  text-align: left;
}
.table-score thead tr th {
  border: 0.5px solid lightgrey;
  padding: 0.2em 1em;
  font-weight: normal;
}
.controls p {
  margin: 0.4rem auto;
}
.controls button {
  margin: 0.2em;
}
.controls div {
  margin: 0.4rem auto;
}
.controls table {
  margin: 0.4rem auto;
}
.turns {
  font-size: small;
  overflow: auto;
  height: 18em;
  width: fit-content;
  padding: 1em;
}
.table-turns {
  margin: auto;
  border: 0.5px solid lightgrey;
  border-collapse: collapse;
  max-width: 31.5rem;
}
.table-turns tr td {
  border: 0.5px solid lightgrey;
}
.table-turns thead tr th {
  border: 0.5px solid lightgrey;
  font-weight: normal;
}
