.toolbar {
  display: flex;
  width: 100%;
  background-color: whitesmoke;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 0.1rem 0;
  position: fixed;
  top: 0px;
  z-index: 999;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.toolbar > * {
  margin: auto;
  padding: 0.1rem;
}

a {
  color: rgb(60, 60, 60);
}
.logout {
  cursor: pointer;
}
.toolbar a {
  cursor: pointer;
}
.lang button {
  padding: 0.2em;
  margin: 0.3em;
  font-size: small;
}
.lang {
  padding: 0;
  margin: auto;
  text-align: "center";
}

@media screen and (max-width: 670px) {
  .toolbar {
    font-size: small;
  }
}
