.collapse {
  display: none;
}

.collapse.in {
  display: initial;
}

.collapseButton {
  margin: 0.5em;
  min-width: 400px;
}
