.room-tile {
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin: 1rem 0.1rem;
  cursor: pointer;
  width: 250px;
  height: 120px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.room-tile:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
.tile-header {
  border-bottom: 1px solid lightgrey;
  border-radius: 4px 4px 0 0;
  height: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.tile-header p {
  margin: auto;
}
.tile-body {
  height: 60%;
  display: flex;
  flex-flow: row wrap;
  padding: 0.1rem 0.8rem;
}
.tile-body p {
  margin: auto;

  overflow: hidden;
  text-overflow: ellipsis;
  font-size: normal;
  word-break: keep-all;
}
@media only screen and (max-width: 420px) {
  .room-tile {
    width: 90vw;
  }
}
.rooms a {
  text-decoration: none;
}
.rooms {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}
.status {
  font-size: small;
  font-weight: normal;
  width: 60%;
  font-weight: bold;
}
.number {
  width: 15%;
  font-size: large;
  font-weight: bold;
  padding-left: 5%;
}
.language {
  width: 15%;
  font-size: small;
  font-weight: bold;
  padding-left: 5%;
}
.counter {
  position: absolute;
  top: -0.8em;
  right: -0.8em;
  background-color: tomato;
  color: white;
  border-radius: 20px;
  width: fit-content;
  min-width: 20px;
  height: 20px;
  font-size: small;
  line-height: 1em;
  padding: 3px;
  box-sizing: border-box;
}
