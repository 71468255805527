.input {
    padding: 12px;
    margin: 24px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    background-color: inherit;
}

.input:focus {
    border-color: #3f51b5;
    outline: none;
}