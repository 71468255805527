.table-board {
  margin: 1em auto;
  border-collapse: collapse;
  width: 31.5em;
  height: 31.5em;
}

.board-table-cell {
  border: 1px solid rgb(211, 211, 211);
  width: 2.1rem;
  height: 2.1rem;
  margin: 0;
  padding: 0;
  color: rgb(60, 60, 60);
}
.center-true {
  background: linear-gradient(
      to top left,
      transparent calc(50% - 2px),
      rgba(220, 220, 220, 0.8) calc(50% - 2px),
      rgba(220, 220, 220, 0.8) calc(50% + 2px),
      transparent calc(50% + 2px)
    ),
    linear-gradient(
      to top right,
      transparent calc(50% - 2px),
      rgba(220, 220, 220, 0.8) calc(50% - 2px),
      rgba(220, 220, 220, 0.8) calc(50% + 2px),
      transparent calc(50% + 2px)
    );
  position: relative;
}
.w3 {
  background-color: rgba(250, 128, 114, 0.5);
}
.l3 {
  background-color: rgba(255, 165, 0, 0.5);
}
.w2 {
  background-color: rgba(70, 130, 180, 0.5);
}
.l2 {
  background-color: rgba(154, 205, 50, 0.5);
}
.user-letter-true {
  color: rgb(221, 43, 43);
}
.new-letter-true {
  color: rgb(43, 160, 43);
  background-color: lightgoldenrodyellow;

  border: lightsalmon;
}
.new-letter-true > p {
  color: rgba(128, 128, 110, 0.7);
}
.value-on-board {
  position: absolute;
  font-size: xx-small;
  z-index: 100;
  top: -1em;
  right: 0;
}
.cell {
  position: relative;
  z-index: -1;
  width: 100%;
  height: 100%;
  font-weight: 600;
  font-size: x-large;
}

.multiply {
  position: absolute;
  font-size: 1.5rem;
  color: whitesmoke;
  top: -1.2em;
  left: 0.2rem;
  z-index: -2;
  font-weight: lighter;
  text-align: center; /* center text horizontally */
  display: flex; /* added flexbox */
  justify-content: center; /* center text horizontally */
  width: 100%;
  left: 0;
}
.unit {
  position: absolute;
  font-size: 0.65rem;
  color: whitesmoke;
  bottom: -1em;
  z-index: -3;
  text-align: center; /* center text horizontally */
  display: flex; /* added flexbox */
  justify-content: center; /* center text horizontally */
  width: 100%;
  left: 0;
}
@media screen and (max-width: 31.5em) {
  .table-board {
    width: 90vmin;
    height: 90vmin;
    table-layout: fixed;
  }
  .board-table-cell {
    width: 6vmin;
    height: 6vmin;
  }
  .cell {
    font-size: 1.1rem;
  }
  .multiply {
    font-size: 1.1rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .unit {
    font-size: 0.48rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .value-on-board {
    font-weight: 300;
    top: -0.7rem;
    right: -0.05rem;
  }
}