.container {
    margin: auto;
    text-align: center;
    max-width: 400px;
}

.title {
    font-size: 24px;
    margin-bottom: 24px;
}

.input {
    width: 100%;
    padding: 12px;
    margin-top: 4px;
    margin-bottom: 24px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    background-color: inherit;
}

.input:focus {
    border-color: #3f51b5;
    outline: none;
}

.errorMessage {
    color: red;
    font-size: 14px;
}

.submitButton {
    width: 100%;
    padding: 12px;
    background-color: #3f51b5;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 12px;
}

.submitButton:hover {
    background-color: #303f9f;
}

.links {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin: 12px auto;
}

.links > a {
    margin: auto;
}
